.heading {
    margin-bottom: var(--g-spacing-md);
    text-align: center;
}

.results {
    margin-top: var(--g-spacing-sm);
}

.result {
    display: block;
    margin-bottom: var(--g-spacing-md);
    text-decoration: none;
    &:last-child {
        margin-bottom: 0;
    }
}

.button {
    display: flex;
    justify-content: center;
    margin-top: var(--g-spacing-lg);
}
